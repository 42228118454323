import React from "react";
import Box from "@mui/material/Box";
import FileSystemDrawer from "../components/storage/FileSystem";
import PdfViewer from "../components/storage/PdfViewer";
// import DocumentEditor from "../components/editor/Document";
import { CssBaseline } from "@mui/material";
import { getStorageURL, getStorageParamURL } from "..//api/storage";
import { useParams } from "react-router-dom";

// const FileSystemDrawer = () => {
//   // Your file-system display component
//   return <Box>File System Drawer</Box>;
// };

// const PDFViewer = () => {
//   // Your PDF viewer component
//   return <Box sx={{ height: "100%" }}>PDF Viewer</Box>;
// };

const DocumentEditor = () => {
  // Your rich text editor component
  return <Box sx={{ height: "100%" }}>Rich Text Editor</Box>;
};

const OverviewComponent = () => {
  // get the case info from our caseId and then the chat from sessionId
  const { caseId, sessionId } = useParams();

  // const pdfURL = "https://arxiv.org/pdf/quant-ph/0410100.pdf";
  // const pdfURL = getStorageURL({ path: "Lambda_Labs_Top_Server.pdf" });
  // const searchTerm = "Heisenberg uncertainty";

  const [fileUrl, setFileUrl] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState(null);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        overflow: "hidden",
        flexDirection: "row",
      }}
    >
      <CssBaseline />

      <Box
        // component={FileSystemDrawer}
        sx={{
          width: "20%",
          display: "flex",
          flexDirection: "row",
          borderRight: "1px solid gray",
          height: "100%",
        }}
      >
        <FileSystemDrawer
          onSelectPath={(path) => {
            const storagePath = getStorageParamURL({
              path: path.storage_location,
            });
            console.log("Set selected path:", storagePath);
            setFileUrl(storagePath);
          }}
          onUploadSuccess={(path) => {
            // refresh of the file system drawer coming soon
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            // height: "10%",
            width: "calc(100% - 16px)",
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid gray",
            m: 1,
          }}
        >
          <h1>Chat History</h1>
        </Box>
        <Box
          sx={{
            display: "flex",
            flex: 10,
            width: "100%",
            height: "100%",
            m: 0,
            p: 0,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid gray",
              m: 1,
              p: 1,
            }}
          >
            <PdfViewer pdfURL={fileUrl} searchTerm={searchTerm} />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid gray",
              m: 1,
              p: 1,
            }}
          >
            <DocumentEditor />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OverviewComponent;
