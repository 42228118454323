import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import { useSpring, animated } from "@react-spring/web";
import { TransitionProps } from "@mui/material/transitions";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

function buildTree(paths) {
  const rootNode = { label: "/", children: {} };
  //   console.log("Paths:", paths);
  paths.forEach((path, pathIx) => {
    console.log("Path:", path);
    const { file_path } = path;
    const parts = file_path.split("/");
    let current = rootNode;
    parts.forEach((part, index) => {
      if (!current.children[part]) {
        const newNode = { label: part, children: {}, ix: pathIx };
        current.children[part] = newNode;
      }
      current = current.children[part];
    });
  });

  return rootNode;
}

const sortChildrenByChildren = (childNodes) => {
  //   order first by children nodes with children, then by children nodes without children, then by leaf nodes
  const sortedChildNodes = childNodes.sort((a, b) => {
    const aHasChildren = Object.keys(a.children).length > 0;
    const bHasChildren = Object.keys(b.children).length > 0;
    if (aHasChildren && !bHasChildren) {
      return -1;
    } else if (!aHasChildren && bHasChildren) {
      return 1;
    } else {
      return 0;
    }
  });
  return sortedChildNodes;
};

function renderTree(node, nodeId, nodeClick, stylePath) {
  const childNodes = Object.values(node.children);

  //   order first by children nodes with children, then by children nodes without children, then by leaf nodes
  const sortedChildNodes = sortChildrenByChildren(childNodes);

  return (
    <StyledTreeItem
      key={nodeId}
      nodeId={nodeId}
      label={node.label}
      style={stylePath && stylePath(node.ix, node)}
      onClick={() => {
        if (nodeClick) {
          nodeClick(node);
        }
      }}
    >
      {sortedChildNodes.map((childNode, index) =>
        renderTree(childNode, `${nodeId}-${index}`, nodeClick, stylePath)
      )}
    </StyledTreeItem>
  );
}

function renderTreeView(paths, onLeafClick, stylePath) {
  const treeData = buildTree(paths);
  //   console.log("Tree data:", treeData);

  const sortedChildNodes = sortChildrenByChildren(
    Object.values(treeData.children)
  );
  return (
    <TreeView
      aria-label="file system navigator"
      defaultExpanded={["1"]}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<SquareRoundedIcon />}
      sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
      //   defaultCollapseIcon={<ExpandMoreIcon />}
      //   defaultExpandIcon={<ChevronRightIcon />}
      //   sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
    >
      {sortedChildNodes.map((node, index) =>
        renderTree(
          node,
          `0-${index}`,
          (nodeInfo) => {
            const filePath = paths[nodeInfo.ix];

            //   if we're a leaf node
            if (Object.keys(nodeInfo.children).length === 0) {
              if (onLeafClick) onLeafClick(filePath);
            }
          },
          (ix, nodeInfo) => {
            if (Object.keys(nodeInfo.children).length === 0) {
              return stylePath(ix);
            }
          }
        )
      )}
    </TreeView>
  );
}

export default function PathTree({ paths, onClick, stylePath }) {
  if (!paths || paths.length === 0) return null;
  // return (
  //   <Box
  //     sx={{
  //       display: "flex",
  //       alignItems: "center",
  //       justifyContent: "center",
  //       width: "100%",
  //       flexGrow: 1,
  //       height: "100%",
  //     }}
  //   >
  //     No Files Found
  //   </Box>
  // );
  //   console.log("Paths:", paths);
  return renderTreeView(paths, onClick, stylePath);
}
