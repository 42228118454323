// useFetchUserFirmsCases.js
import { useState, useEffect } from "react";
import { supabase } from "../auth/supabaseClient";

const useFirmsAndCases = (userId) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData(userId);
  }, [userId]);

  const fetchData = async (userId) => {
    setLoading(true);
    setError(null);
    try {
      const { data: fetchedData, error: fetchError } = await supabase
        .from("user_firms_cases")
        .select("*")
        .eq("user_id", userId);

      console.log("Firms: ", fetchedData);

      if (fetchError) {
        setError(fetchError);
      } else {
        setData(fetchedData);
      }
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  return { data, loading, error };
};

export default useFirmsAndCases;
