import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signInWithPassword } from "../../auth"; // Import the function
import { useSnackbar } from "notistack";
const LoginPage = () => {
  const navigateTo = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleLogin = async (event) => {
    event.preventDefault();
    const { data, error } = await signInWithPassword(email, password); // Use the function
    if (error) {
      console.log("Error logging in:", error.message);
      enqueueSnackbar("Error logging in: " + error.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    } else {
      console.log("Logged in:", data);
      // go to home page!
      navigateTo("/");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-default">
      <div className="bg-login-signup w-full max-w-md rounded-xl shadow-md p-6 space-y-6">
        <h1 className="text-3xl font-semibold text-center">Login</h1>
        <form onSubmit={handleLogin} className="space-y-4">
          <div className="form-control">
            <label htmlFor="email" className="label">
              <span className="label-text">Email</span>
            </label>
            <input
              onChange={(event) => setEmail(event.target.value)}
              type="email"
              id="email"
              className="input input-bordered"
              required
            />
          </div>
          <div className="form-control">
            <label htmlFor="password" className="label">
              <span className="label-text">Password</span>
            </label>
            <input
              onChange={(event) => setPassword(event.target.value)}
              type="password"
              id="password"
              className="input input-bordered"
              required
            />
          </div>
          <button
            type="submit"
            className="btn text-white bg-primary border-primary border w-full font-semibold"
          >
            Login
          </button>
        </form>
        <div className="text-center">
          <Link to="/auth/forgot" className="text-sm text-blue-500">
            Forgot password?
          </Link>
        </div>

        <div className="flex flex-col space-y-4">
          <button
            onClick={() => {
              // Implement your Google login logic here
            }}
            className="btn btn-outline btn-accent w-full"
          >
            Login with Google
          </button>
          <button
            onClick={() => {
              // Implement your other auth provider login logic here
            }}
            className="btn btn-outline btn-accent w-full"
          >
            Login with Other Provider
          </button>
        </div>
        <div className="text-center">
          <p className="text-sm">
            Don't have an account?{" "}
            <Link to="/auth/signup" className="text-blue-500">
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
