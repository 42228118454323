import React, { useState } from "react";

// import CircularProgress from '@mui/material/CircularProgress';

import LoginPage from "./components/auth/LoginPage";
import SignupPage from "./components/auth/SignupPage";
import ForgotPassword from "./components/auth/ForgotPassword";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import { useAuthSession } from "./hooks/useAuthSession";
import { signOut } from "./auth";
// import Home from "./routes/Home";
import Case from "./routes/Case";
import Admin from "./routes/Admin";
import Overview from "./routes/Overview";

export function RedirectIfLoggedIn({ children, session = {}, redirect = "/" }) {
  const { user, waitingForSession } = session; // useAuthSession();
  if (user) {
    return <Navigate to={redirect} replace />;
  }
  return children;
}

export function ProtectedRoute({
  children,
  session = {},
  redirect = "/auth/login",
}) {
  const { user, waitingForSession } = session; // useAuthSession();

  console.log("Is init change:", waitingForSession);
  if (waitingForSession) {
    return <progress className="progress w-56"></progress>;
  }

  console.log("Current Session: ", session);
  if (!user) {
    console.log("Protected route, no auth, redirecting to sign in", user);
    return <Navigate to={redirect} replace />;
  }

  return children;
}
const Layout = ({ children, session }) => {
  const { user } = session;
  const handleLogout = async () => {
    // Implement your logout logic here
    await signOut();
    console.log("Logged out");
  };

  return <Outlet />;
};

const ProtectedHome = ({ session }) => {
  return (
    <ProtectedRoute session={session}>
      {/* <Home /> */}
      <Overview />
    </ProtectedRoute>
  );
};
const ProtectedAdmin = ({ session }) => {
  return (
    <ProtectedRoute session={session}>
      <Admin />
    </ProtectedRoute>
  );
};

const ProtectedCase = ({ session }) => {
  return (
    <ProtectedRoute session={session}>
      {/* <Home /> */}
      <Case />
    </ProtectedRoute>
  );
};

const ProtectedComponent = ({ session, UserComponent }) => {
  return (
    <ProtectedRoute session={session}>
      <UserComponent />
    </ProtectedRoute>
  );
};

const App = () => {
  const session = useAuthSession();
  console.log("App session:", session);

  // return <Layout />;
  return (
    <Routes>
      <Route index path="/" element={<ProtectedHome session={session} />} />
      <Route path="/admin" element={<ProtectedAdmin session={session} />} />
      <Route
        path="/s/:caseId"
        element={<ProtectedComponent session={session} UserComponent={Case} />}
      />
      <Route
        path="/s/:caseId/:sessionId"
        element={<ProtectedComponent session={session} UserComponent={Case} />}
      />
      <Route path="/auth" element={<Layout session={session} />}>
        <Route index element={<ProtectedHome session={session} />} />
        <Route
          path="/auth/login"
          element={
            <RedirectIfLoggedIn session={session}>
              <LoginPage />
            </RedirectIfLoggedIn>
          }
        />
        <Route
          path="/auth/signup"
          element={
            <RedirectIfLoggedIn session={session}>
              <SignupPage />
            </RedirectIfLoggedIn>
          }
        />

        <Route
          path="/auth/forgot"
          element={
            <RedirectIfLoggedIn session={session}>
              <ForgotPassword />
            </RedirectIfLoggedIn>
          }
        />
      </Route>
    </Routes>
  );
};

export default App;
