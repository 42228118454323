import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { resetPasswordForEmail } from "../../auth";

const ForgotPassword = () => {
  const navigateTo = useNavigate();
  const [email, setEmail] = useState("");

  const handleResetPassword = async (event) => {
    event.preventDefault();
    const { data, error } = await resetPasswordForEmail(email); // Use the function
    if (error) {
      console.error("Error resetting:", error.message);
    } else {
      console.log("Reset successful:", data);
      // go to login form!
      navigateTo("/auth/login");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-default">
      <div className="bg-login-signup w-full max-w-md rounded-xl shadow-md p-6 space-y-6">
        <h1 className="text-3xl font-semibold text-center">Forgot Password</h1>
        <form onSubmit={handleResetPassword} className="space-y-4">
          <div className="form-control">
            <label htmlFor="email" className="label">
              <span className="label-text">Email</span>
            </label>
            <input
              onChange={(event) => setEmail(event.target.value)}
              type="email"
              id="email"
              className="input input-bordered"
              required
            />
          </div>
          <button
            type="submit"
            className="btn text-white bg-primary border-primary border w-full font-semibold"
          >
            Reset Password
          </button>
        </form>
        <div className="text-center">
          <p className="text-sm">
            Remembered your password?{" "}
            <Link to="/auth/login" className="text-blue-500">
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
