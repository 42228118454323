// useSession.js
import { useState, useEffect, useCallback } from "react";
import { supabase } from "../auth/supabaseClient";
import { useSnapshot } from "valtio";
import { proxy } from "valtio";
import _ from "lodash";

export const fileState = proxy({
  files: {},
});

const useCaseFiles = (caseId, depth = 0, parent = "/") => {
  const [files, setFiles] = useState([]);
  const [detailedFiles, setDetailedFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFiles = useCallback(async () => {
    // const fetchFiles = async () => {
    setLoading(true);
    try {
      let query = supabase
        .from("files")
        .select("*")
        .eq("case_id", caseId)
        .eq("depth", depth);

      if (parent) {
        query = query.eq("parent_path", parent);
      }

      const { data, error } = await query;

      if (error) {
        throw error;
      }
      setDetailedFiles(data);
      setFiles(_.map(data, "file_path"));
    } catch (error) {
      console.error("Error fetching files:", error);
    } finally {
      setLoading(false);
    }
    //   };
  }, [caseId, depth, parent]);

  useEffect(() => {
    fetchFiles();
  }, [caseId, depth, fetchFiles]);

  const refetchAtDepth = async (ndepth) => {
    fetchFiles(ndepth);
  };

  return { files, detailedFiles, loading, refetchAtDepth };
};

export default useCaseFiles;
