import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signUp } from "../../auth"; // Import the function

const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");

  const navigateTo = useNavigate();
  const handleSignup = async (event) => {
    event.preventDefault();

    console.log(
      "Attempting handle signup with",
      email,
      password,
      confirmPassword
    );

    if (password !== confirmPassword) {
      console.error("Passwords do not match");
      return;
    }

    const { data, error } = await signUp(email, password, username); // Use the function
    if (error) {
      console.error("Error signing up:", error.message);
      console.log(error);
    } else {
      console.log("sign up completed!", data);
      // go to login form!
      navigateTo("/auth/login");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-default">
      <div className="bg-login-signup w-full max-w-md rounded-xl shadow-md p-6 space-y-6">
        <h1 className="text-3xl font-semibold text-center">Sign Up</h1>
        <form onSubmit={handleSignup} className="space-y-4">
          <div className="form-control">
            <label htmlFor="email" className="label">
              <span className="label-text">Email</span>
            </label>
            <input
              type="email"
              id="email"
              onChange={(event) => setEmail(event.target.value)}
              className="input input-bordered"
              required
            />
          </div>
          <div className="form-control">
            <label htmlFor="username" className="label">
              <span className="label-text">Username</span>
            </label>
            <input
              type="username"
              id="username"
              onChange={(event) => setUsername(event.target.value)}
              className="input input-bordered"
              required
            />
          </div>
          <div className="form-control">
            <label htmlFor="password" className="label">
              <span className="label-text">Password</span>
            </label>
            <input
              onChange={(event) => setPassword(event.target.value)}
              type="password"
              id="password"
              className="input input-bordered"
              required
            />
          </div>
          <div className="form-control">
            <label htmlFor="confirm-password" className="label">
              <span className="label-text">Confirm Password</span>
            </label>
            <input
              onChange={(event) => setConfirmPassword(event.target.value)}
              type="password"
              id="confirm-password"
              className="input input-bordered"
              required
            />
          </div>
          <button
            type="submit"
            className="btn text-white bg-primary border-primary border w-full font-semibold"
          >
            Sign Up
          </button>
        </form>
        <div className="text-center">
          <p className="text-sm">
            Already have an account?{" "}
            <Link to="/auth/login" className="text-blue-500">
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
