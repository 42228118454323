import React, { useState, useEffect } from "react";
import { supabase } from "../auth/supabaseClient";
import {
  Box,
  Tab,
  Tabs,
  TextField,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Grid,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const copyToClipboard = async (text, enqueueSnackbar) => {
  try {
    await navigator.clipboard.writeText(text);
    // alert(`Copied to clipboard: "${text}"`);
    enqueueSnackbar(`Copied to clipboard: "${text}"`, {
      variant: "success",
      autoHideDuration: 1000,
    });
  } catch (error) {
    console.error("Failed to copy text: ", error);
    enqueueSnackbar(`Failed to copy text: ${error}`, { variant: "error" });
  }
};

const useTableData = (tableName) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabase.from(tableName).select("*");
      if (!error) {
        setData(data);
      }
    };

    fetchData();
  }, [tableName]);

  return [data, setData];
};

const Admin = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [firms, setFirms] = useTableData("firms");
  const [files, setFiles] = useTableData("files");
  const [firmMemberships, setFirmMemberships] =
    useTableData("firm_memberships");
  const [casePermissions, setCasePermissions] =
    useTableData("case_permissions");
  const [filePermissions, setFilePermissions] =
    useTableData("file_permissions");
  const [cases, setCases] = useTableData("cases");
  const [chatSessions, setChatSessions] = useTableData("chat_sessions");
  const [chatMessages, setChatMessages] = useTableData("chat_messages");
  const [inputs, setInputs] = useState({
    firmName: "",
    caseFirmId: "",
    chatCaseId: "",
    chatUserId: "",
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => setSelectedTab(newValue);
  //   const handleInputChange = (event) =>
  //     setInputs({ ...inputs, [event.target.name]: event.target.value });

  const handleInputChange = (event) => {
    const { name, type, value, checked } = event.target;
    setInputs((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const createEntry = async (tableName, insertData, tableData, setData) => {
    console.log("Entry data:", insertData);
    const { data: newData, error } = await supabase
      .from(tableName)
      .insert([insertData])
      .select();

    if (!error) {
      setData([...tableData, newData[0]]);
      setInputs({});
    }
  };

  const createTable = (rows, columns, action) => (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-start"
      sx={{
        width: "100%",
      }}
    >
      <Grid
        item
        sx={{
          flexGrow: 1,
        }}
      >
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      justifyContent: "flex-start",
                      fontWeight: "bold",
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
                {action && <TableCell>Action</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  {Object.values(row).map((value, index) => (
                    <TableCell
                      key={index}
                      onClick={() => copyToClipboard(value, enqueueSnackbar)}
                      style={{ cursor: "pointer" }}
                    >
                      {value && value.toString()}
                    </TableCell>
                  ))}
                  {action && (
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => action.onClick(row)}
                      >
                        {action.label}
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );

  const forms = [
    {
      tableName: "firms",
      tableData: firms,
      setTableData: setFirms,
      columns: ["id", "name", "created_at"],
      inputs: [{ name: "firmName", label: "Firm Name" }],
      createData: { name: inputs.firmName },
    },
    {
      tableName: "cases",
      tableData: cases,
      setTableData: setCases,
      columns: ["id", "firm_id", "name", "created_at", "description"],
      inputs: [
        { name: "caseFirmId", label: "Firm ID" },
        { name: "caseName", label: "Case Name" },
        { name: "caseDescription", label: "Case Description" },
      ],
      createData: {
        firm_id: inputs.caseFirmId,
        name: inputs.caseName,
        description: inputs.caseDescription,
      },
    },
    {
      // CREATE TABLE files (
      //     id UUID PRIMARY KEY DEFAULT uuid_generate_v4(),
      //     case_id UUID NOT NULL references cases(id),
      //     summary_id UUID references summaries(id),
      //     file_path TEXT NOT NULL,
      //     storage_location TEXT NOT NULL,
      //     last_updated TIMESTAMP WITH TIME ZONE DEFAULT NOW()
      //   );
      //   ALTER TABLE files ADD COLUMN depth INTEGER NOT NULL DEFAULT 0;
      //   ALTER TABLE files ADD COLUMN is_directory BOOLEAN NOT NULL DEFAULT false;
      //   ALTER TABLE files ADD COLUMN parent_path TEXT NOT NULL DEFAULT '/';
      tableName: "files",
      tableData: files,
      setTableData: setFiles,
      columns: [
        "id",
        "case_id",
        "summary_id",
        "file_path",
        "storage_location",
        "last_updated",
        "depth",
        "is_directory",
        "parent_path",
      ],
      inputs: [
        { name: "fileCaseId", label: "Case ID" },
        { name: "fileSummaryId", label: "Summary ID" },
        { name: "filePath", label: "File Path" },
        { name: "fileStorageLocation", label: "Storage Location" },
        { name: "fileDepth", label: "Depth" },
        { name: "fileIsDirectory", label: "Is Directory", type: "checkbox" },
        { name: "fileParentPath", label: "Parent Path" },
      ],
      createData: {
        case_id: inputs.fileCaseId,
        summary_id: inputs.fileSummaryId,
        file_path: inputs.filePath,
        storage_location: inputs.fileStorageLocation,
        depth: inputs.fileDepth,
        is_directory: inputs.fileIsDirectory,
        parent_path: inputs.fileParentPath,
      },
    },
    {
      tableName: "firm_memberships",
      tableData: firmMemberships,
      setTableData: setFirmMemberships,
      columns: ["user_id", "firm_id", "is_admin"],
      inputs: [
        { name: "membershipUserId", label: "User ID" },
        { name: "membershipFirmId", label: "Firm ID" },
        { name: "membershipIsAdmin", label: "Is Admin", type: "checkbox" },
      ],
      createData: {
        user_id: inputs.membershipUserId,
        firm_id: inputs.membershipFirmId,
        is_admin: inputs.membershipIsAdmin,
      },
    },

    {
      tableName: "case_permissions",
      tableData: casePermissions,
      setTableData: setCasePermissions,
      columns: [
        "user_id",
        "case_id",
        "is_admin",
        "can_view",
        "can_upload_files",
        "can_edit",
        "can_create_summary",
      ],
      inputs: [
        { name: "casePermissionUserId", label: "User ID" },
        { name: "casePermissionCaseId", label: "Case ID" },
        { name: "casePermissionIsAdmin", label: "Is Admin", type: "checkbox" },
        { name: "casePermissionCanEdit", label: "Can Edit", type: "checkbox" },
        {
          name: "casePermissionCanCreateSummary",
          label: "Can Create Summary",
          type: "checkbox",
        },
        {
          name: "casePermissionCanUploadFiles",
          label: "Can Upload Files",
          type: "checkbox",
        },
        { name: "casePermissionCanView", label: "Can View", type: "checkbox" },
      ],
      createData: {
        user_id: inputs.casePermissionUserId,
        case_id: inputs.casePermissionCaseId,
        is_admin: inputs.casePermissionIsAdmin,
        can_view: inputs.casePermissionCanView,
        can_edit: inputs.casePermissionCanEdit,
        can_create_summary: inputs.casePermissionCanCreateSummary,
        can_upload_files: inputs.casePermissionCanUploadFiles,
      },
    },
    {
      //   CREATE TABLE file_permissions (
      //     user_id UUID NOT NULL references users(id),
      //     file_id UUID NOT NULL references files(id),
      //     can_edit BOOLEAN DEFAULT false,
      //     can_view BOOLEAN DEFAULT false,
      //     can_comment BOOLEAN DEFAULT false,
      //     PRIMARY KEY (user_id, file_id)
      //   );
      tableName: "file_permissions",
      tableData: filePermissions,
      setTableData: setFilePermissions,
      columns: ["user_id", "file_id", "can_edit", "can_view", "can_comment"],
      inputs: [
        { name: "filePermissionUserId", label: "User ID" },
        { name: "filePermissionFileId", label: "File ID" },
        { name: "filePermissionCanEdit", label: "Can Edit", type: "checkbox" },
        { name: "filePermissionCanView", label: "Can View", type: "checkbox" },
        {
          name: "filePermissionCanComment",
          label: "Can Comment",
          type: "checkbox",
        },
      ],
      createData: {
        user_id: inputs.filePermissionUserId,
        file_id: inputs.filePermissionFileId,
        can_edit: inputs.filePermissionCanEdit,
        can_view: inputs.filePermissionCanView,
        can_comment: inputs.filePermissionCanComment,
      },
    },
    {
      tableName: "chat_sessions",
      tableData: chatSessions,
      setTableData: setChatSessions,
      columns: ["id", "case_id", "user_id", "created_at"],
      action: {
        label: "Go To",
        onClick: (row) => {
          const url = `/s/${row.case_id}/${row.user_id}`;
          enqueueSnackbar(`Go To: ${url}`, {
            variant: "success",
            autoHideDuration: 2000,
          });
          navigate(url);
        },
      },
      inputs: [
        { name: "chatCaseId", label: "Case ID" },
        { name: "chatUserId", label: "User ID" },
      ],
      createData: { case_id: inputs.chatCaseId, user_id: inputs.chatUserId },
    },
    // now we're doing the same for chat_messages
    // CREATE TABLE chat_messages (
    //     id UUID PRIMARY KEY DEFAULT uuid_generate_v4(),
    //     session_id UUID NOT NULL references chat_sessions(id),
    //     user_id UUID references users(id),
    //     bot_id UUID references bots(id),
    //     message TEXT NOT NULL,
    //     is_bot BOOLEAN NOT NULL DEFAULT false,
    //     created_at TIMESTAMP WITH TIME ZONE DEFAULT NOW()
    //   );
    {
      tableName: "chat_messages",
      tableData: chatMessages,
      setTableData: setChatMessages,
      columns: [
        "id",
        "session_id",
        "user_id",
        "bot_id",
        "message",
        "is_bot",
        "created_at",
      ],
      inputs: [
        { name: "chatMessageSessionId", label: "Session ID" },
        { name: "chatMessageUserId", label: "User ID" },
        { name: "chatMessageBotId", label: "Bot ID" },
        { name: "chatMessageMessage", label: "Message" },
        { name: "chatMessageIsBot", label: "Is Bot", type: "checkbox" },
      ],
      createData: {
        session_id: inputs.chatMessageSessionId,
        user_id: inputs.chatMessageUserId,
        bot_id: inputs.chatMessageBotId,
        message: inputs.chatMessageMessage,
        is_bot: inputs.chatMessageIsBot,
      },
    },
  ];
  const renderForms = () => {
    const currentForm = forms[selectedTab];
    return (
      <Box>
        {createTable(
          currentForm.tableData,
          currentForm.columns,
          currentForm.action
        )}
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ marginTop: 2 }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {currentForm.inputs.map((input) => (
              <Grid
                container
                spacing={1}
                alignItems="center"
                key={input.name}
                item
                xs={12}
                md={8}
                justifyContent="center"
              >
                <Grid item>
                  <Typography>{input.label}:</Typography>
                </Grid>
                <Grid item>
                  {input.type === "checkbox" ? (
                    <Checkbox
                      name={input.name}
                      checked={inputs[input.name] || false}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <TextField
                      name={input.name}
                      value={inputs[input.name] || ""}
                      onChange={handleInputChange}
                      sx={{ minWidth: "140px" }}
                    />
                  )}
                </Grid>
              </Grid>
            ))}
          </Box>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                createEntry(
                  currentForm.tableName,
                  currentForm.createData,
                  currentForm.tableData,
                  currentForm.setTableData
                )
              }
            >
              Create {currentForm.tableName.split("_").join(" ")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Box>
      <Typography variant="h4">Admin Dashboard</Typography>
      <Tabs value={selectedTab} onChange={handleChange}>
        {forms.map((form, index) => (
          <Tab label={form.tableName} />
        ))}
        {/* // <Tab label="Firms" />
        // <Tab label="Firm Memberships" />
        // <Tab label="Cases" />
        // <Tab label="Chat Sessions" /> */}
      </Tabs>
      {renderForms()}
    </Box>
  );
};

export default Admin;
