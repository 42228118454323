// FileSystemDrawer.jsx
import React from "react";
import { Box, Typography } from "@mui/material";
// import { Dropzone } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import PathTree from "./PathTree";
import { uploadFile } from "../../api/storage";
import _ from "lodash";
import { useParams } from "react-router-dom";
import useCaseFiles from "../../hooks/useCaseFiles";
import useAuthSession from "../../hooks/useAuthSession";

const DropZone = styled(Box)(({ theme }) => ({
  border: "2px dashed",
  borderColor: theme.palette.secondary.main,
  borderRadius: theme.spacing(1),
  textAlign: "center",
  padding: theme.spacing(2),
  cursor: "pointer",
}));
const FileSystemDrawer = ({
  accept = {
    "application/doc": [".pdf", ".doc", ".docx"],
  },
  maxFiles = 1,
  onSelectPath,
  onUploadSuccess,
}) => {
  const { caseId } = useParams();
  const { user, access_token } = useAuthSession();
  const { detailedFiles, loading, refetchAtDepth } = useCaseFiles(caseId);
  const onDrop = (acceptedFiles) => {
    // Process and upload the dropped files here
    console.log("Sending files: ", acceptedFiles);
    // loop acceptedFiles
    _.map(acceptedFiles, async (file) => {
      console.log(
        "File: ",
        file,
        "user info:",
        user,
        " Access :",
        access_token
      );
      await uploadFile(file, caseId, access_token);
      if (onUploadSuccess) onUploadSuccess({ file, caseId });
    });
    // now do a refresh on the files for useCaseFiles
    refetchAtDepth(0);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    // maxFiles: 1,
  });

  const { style, ...props } = getRootProps();
  React.useEffect(() => {
    console.log("FILES RENDER:", detailedFiles);
  }, [detailedFiles]);
  return (
    <Box
      name="file-system-drawer"
      sx={{
        p: 2,
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <Typography variant="h6">File System</Typography>
      <Box
        sx={{
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          mt: 1,
          alignItems: "space-between",
          //   justifyContent: "flex-end",
          border: "1px solid gray",
          borderRadius: 1,
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: detailedFiles.length === 0 ? "none" : "flex",
          }}
        >
          <Box>
            <PathTree
              paths={detailedFiles}
              stylePath={(ix) => {
                return {};
              }}
              onClick={(filePath) => {
                console.log("Clicked on file:", filePath);
                if (onSelectPath) onSelectPath(filePath);
              }}
            />
          </Box>
        </Box>
        <Box sx={{ p: 1, flexGrow: 1 }}>
          {/* <DropZone onDrop={onDrop} accept=".pdf,.doc,.docx"> */}
          <DropZone
            {...props}
            style={{
              style,
              ...{
                border: "2px dashed gray",
                borderRadius: 1,
                height: "100%",
                px: 2,
                py: 1,
                flexGrow: 1,
                textAlign: "center",
                cursor: "pointer",
              },
            }}
          >
            <input {...getInputProps()} />
            <Typography>
              {isDragActive
                ? "Drop PDF or DOC files here..."
                : "Drag and drop files here"}
            </Typography>

            {/* <Typography variant="body2">
                  Drag and drop a PDF or DOC file here, or click to select
                  file(s)
                </Typography> */}
          </DropZone>
        </Box>
      </Box>
    </Box>
  );
};

export default FileSystemDrawer;
