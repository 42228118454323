import React from "react";
import {
  Box,
  Card,
  CardContent,
  CssBaseline,
  Typography,
  Button,
  Avatar,
} from "@mui/material";
import useFirmsAndCases from "../hooks/useFirmsAndCases";
import { useAuthSession } from "../hooks/useAuthSession";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";

const OverviewComponent = () => {
  const { user } = useAuthSession();
  const { data, loading, error } = useFirmsAndCases(user.id);
  const navigate = useNavigate();

  return (
    <Box
      sx={
        {
          // ...other Box styling properties
        }
      }
    >
      <CssBaseline />
      <Typography variant="h3" component="h2" gutterBottom>
        Your Firms
      </Typography>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      <Box
        sx={
          {
            // ...other Box styling properties
          }
        }
      >
        {data.map((item) => (
          <Box
            key={item.case_id}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-around",
              border: "1px solid",
              borderRadius: 1,
              borderColor: "primary.main",
              padding: 2,
              backgroundColor: "background.paper",
              width: "100%",
              marginBottom: 2,
            }}
          >
            <Typography variant="h4" component="h3" gutterBottom>
              {item.firm_name}
            </Typography>
            <Card
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                borderTop: "1px solid",
                borderColor: "secondary.main",
                borderRadius: 0,
                paddingTop: 2,
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="h5"
                  component="h4"
                  color="secondary.main"
                  gutterBottom
                >
                  {item.case_name}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ marginTop: 1, opacity: 0.7 }}
                >
                  {item.case_description}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="secondary"
                  onClick={() => navigate(`/s/${item.case_id}`)}
                >
                  View Case
                </Button>
                <Button
                  color="secondary"
                  onClick={() => navigate(`/settings/${item.case_id}`)}
                >
                  <SettingsIcon />
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {/* Assuming 'case_users' contains the list of users associated with the case */}
                  {item.case_users.map((user) => (
                    <Avatar
                      sx={{ marginLeft: 1 }}
                      key={user.id}
                      src={user.avatar}
                      onClick={() => navigate(`/user/${user.id}`)}
                    />
                  ))}
                </Box>
              </Box>
            </Card>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default OverviewComponent;
