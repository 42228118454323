// api.js
import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

const uploadFile = async (file, caseId, token) => {
  try {
    console.log(
      "Uploading file to API @ ",
      apiURL,
      " file:",
      file.type,
      file.name
    );
    const response = await axios.post(`${apiURL}/storage/action`, file, {
      headers: {
        "Content-Type": file.type,
        "X-Filename": file.name,
        "X-Case": caseId,
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    return null;
  }
};

const getStorageURL = ({ path }) => {
  return `${apiURL}/storage/${path}/read`;
};

const getStorageParamURL = ({ path }) => {
  const base64Path = btoa(path);
  return `${apiURL}/storage/action?path=${base64Path}`;
};

export { uploadFile, getStorageURL, getStorageParamURL };
