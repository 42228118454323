import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { pdfjs } from "react-pdf";
import Box from "@mui/material/Box";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

const PdfViewer = ({ pdfURL, searchTerm }) => {
  const [numPages, setNumPages] = useState(null);
  const [curPage, setCurPage] = useState(0);
  const [pagesText, setPagesText] = useState([]);
  const [highlightedText, setHighlightedText] = useState([]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // ... (omitting unchanged code for brevity) ...

  const pdfViewerStyle = {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "center",
    flexGrow: 1,
    width: "100%",
    height: "100%",
    overflow: "auto",
  };

  const documentStyle = {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  };

  const pageStyle = {
    width: "100%",
  };

  return (
    <Box className="pdf-viewer" sx={pdfViewerStyle}>
      {pdfURL && pdfURL !== "" && (
        <Document
          file={pdfURL}
          onLoadSuccess={onDocumentLoadSuccess}
          style={documentStyle}
        >
          <Page
            key={`page-${curPage + 1}`}
            pageNumber={curPage + 1}
            //   width={Math.floor(window.innerWidth * 0.4)}
            width={Math.floor(window.innerWidth * 0.4)}
            style={pageStyle}
            className="pdf-page"
            // ... (rest of the Page component custom props) ...
          />

          {/* {Array.from(new Array(numPages), (_, index) => (
          <Page
            key={`page-${index + 1}`}
            pageNumber={index + 1}
            width={Math.floor(window.innerWidth * 0.4)}
            style={pageStyle}
            className="pdf-page"
            // ... (rest of the Page component custom props) ...
          />
        ))} */}
        </Document>
      )}
    </Box>
  );
};

export default PdfViewer;
