// auth.js
import { proxy } from "valtio";
import supabase from "./supabaseClient";

export const sessionState = proxy({
  session: {
    waitingForSession: true,
    subscriptions: {},
  },
});

export const signInWithPassword = async (email, password) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });
  return { data, error };
};

export const signUp = async (email, password, username) => {
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
    options: {
      data: {
        username,
      },
    },
  });
  return { data, error };
};

export const resetPasswordForEmail = async (email, redirectTo) => {
  const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo,
  });
  return { data, error };
};

export const signOut = async () => {
  const { data, error } = await supabase.auth.signOut();
  return { data, error };
};

supabase.auth.onAuthStateChange((event, session) => {
  console.log("AUTH", event, session);

  if (session)
    sessionState.session = {
      ...sessionState.session,
      ...session,
      waitingForSession: false,
    };
  else sessionState.session = { subscriptions: {}, waitingForSession: false };
});
